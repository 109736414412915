import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import logs from './logs'
import tours from './tours'
import users from './users'
import pages from './pages'
import usage from './usage'
import forms from './forms'
import privacy from './privacy'
import helpers from './helpers'
import tenants from './tenants'
import security from './security'
import counters from './counters'
import webhooks from './webhooks'
import templates from './templates'
import analytics from './analytics'
import campaigns from './campaigns'
import revisions from './revisions'
import appConfig from './app-config'
import developers from './developers'
import connectors from './connectors'
import formFields from './form-fields'
import elements from './elements'
import themes from './themes'
import transactions from './transactions'
import organization from './organization'
import integrations from './integrations'
import verticalMenu from './vertical-menu'
import domainMappings from './domain-mappings'
import connectorSettings from './connector-settings'
import preferenceCenters from './preference-centers'
import formConfigurations from './form-configurations'
import regularExpressions from './regular-expressions'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    logs,
    tours,
    users,
    pages,
    usage,
    forms,
    privacy,
    tenants,
    helpers,
    webhooks,
    security,
    counters,
    revisions,
    appConfig,
    analytics,
    campaigns,
    templates,
    connectors,
    formFields,
    developers,
    elements,
    themes,
    transactions,
    organization,
    integrations,
    verticalMenu,
    domainMappings,
    connectorSettings,
    preferenceCenters,
    formConfigurations,
    regularExpressions,
  },
  actions: {
    notify({}, payload) {
      const instance = new Vue()

      instance.$bvToast.toast(payload.body, {
        title: payload.title ? payload.title : 'Notification',
        toaster: payload.toaster ? payload.toaster : 'b-toaster-top-right',
        variant: payload.variant ? payload.variant : 'primary',
        solid: true,
        autoHideDelay: 5000,
      })
    },
  },
  mutations: {},
  strict: process.env.DEV,
})
